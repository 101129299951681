const otpErrorReasonToMessage = (t, reason, productData) => {
    switch (reason) {
        case "PRODUCT_ONEOFF_HYBRID" :
            return t("error.ProductIsHybrid", {internalId: productData.internalId, externalId: productData.externalId});
        case "USER_NOT_ELIGIBLE_ERROR" :
            return t("error.IneligibleUser");
        default:
            return t("error.TechnicalError");
    }
}

const otpErrorToMessage = (error) => {
    switch (error.errorCode) {
        case 'USER_NOT_ELIGIBLE':
            return 'error.otp.IneligibleUser';
        case 'USER_TRANSACTION_LIMIT_REACHED':
            return 'error.TransactionCapping';
        case 'USER_MONTHLY_LIMIT_REACHED':
            return 'error.MonthlyCapping';
        case 'USER_BLACKLISTED':
            return 'error.IneligibleUser';
        case 'OTP_CODE_INVALID':
            return 'error.otp.InvalidConfirmationCode';
        case 'TOO_MANY_OTP_CODE_RETRIES':
            return 'error.otp.TooManyRetries';
        case 'INVALID_MSISDN_PROVIDED_FOR_OTP_CODE_GENERATION':
            return 'error.otp.InvalidMsisdn';
        case 'OTP_CLIENT_GENERIC_ERROR':
            return 'error.otp.TechnicalError';
        default:
            return error.message;
    }
}

export {otpErrorReasonToMessage, otpErrorToMessage};
