import config from "../../components/config/config";
import React from "react";

const errorCodesWithSelfcareLink = ["SVC0007", "POL1001", "3010", "SVC0270"];

const errorCodeToMessage = (t, errorCode) => {
    return <>{parseErrorCode(t, errorCode)}
        {errorCodesWithSelfcareLink.includes(errorCode) &&
            <a target="_blank" rel="noreferrer" href={config.selfcareLink}>{t("error.clickHere")}.</a>}
    </>;
};

const parseErrorCode = (t, errorCode) => {
    switch (errorCode) {
        case 'USER_BLACKLISTED':
            return t("error.IneligibleUser");
        case "POL0253" :
            return t("error.IneligibleUser");
        case "USER_NOT_ELIGIBLE" :
            return t("error.otp.IneligibleUser");
        case "POL0254":
            return t("error.TransactionCapping");
        case "USER_TRANSACTION_LIMIT_REACHED":
            return t("error.TransactionCapping");
        case "POL1000":
            return t("error.NotEnoughCredit");
        case "USER_MONTHLY_LIMIT_REACHED":
            return t("error.MonthlyCapping");
        case "POL1004":
            return t("error.MiniRefundAmount");
        case "POL1002" :
            return t("error.TechnicalError");
        case "3010" :
            return t("error.subscriptionExists");
        default:
            return t("error.TechnicalError");
    }
};

export default errorCodeToMessage;
