const createRedirectUrl = (userData, success, productData, location) => {
    let redirectParams;
    if (productData) {
        redirectParams = {
            redirectURI: productData.urlBackToStore,
            errorURI: productData.errorUrlBackToStore,
            serviceId: userData.serviceId,
            partnerData: userData.partnerData
        }
        return success ? prepareUrlFrom(redirectParams.redirectURI) : prepareUrlFrom(redirectParams.errorURI)
    } else {
        const searchParams = new URLSearchParams(location.search);
        let language = searchParams.get('language');
        return '/consent_dcb/error' + (language && '?language=' + language);
    }

    function prepareUrlFrom(uri) {
        let url = (new URL(uri));
        url.searchParams.append('serviceId', redirectParams.serviceId);
        url.searchParams.append('partnerData', redirectParams.partnerData);
        url.searchParams.append('productId', redirectParams.serviceId);
        return url.href
    }
}

export default createRedirectUrl;
