const apiUrl = "http://localhost:8883";
const popUpDisplayTime = 5000;
const selfcareLink = "http://example.com";
const confirmButtonId = "confirmButtonId";

const config = {
    apiUrl,
    popUpDisplayTime,
    selfcareLink,
    confirmButtonId,
}

export default config
